/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;
// @import "@angular/material/prebuilt-themes/indigo-pink.css";


// include standard theme for bl19
@import "themes/bl19_theme.scss";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($bl19-typography);`
// @include mat.all-component-typographies($bl19-typography);
@include mat.core();
@include mat.all-component-themes($bl19-light-theme);



//$bl-background-color: map-get($bl19-theme-primary, background );
$bl-background-color-light: mat.get-color-from-palette($bl19-theme-primary, 50);
$bl-background-color-middle: mat.get-color-from-palette($bl19-theme-primary, 100);
$bl-background-color-dark: mat.get-color-from-palette($bl19-theme-primary, 200);
$bl-background-color-darkest: mat.get-color-from-palette($bl19-theme-primary, 500);

$bl-accent-color-light: mat.get-color-from-palette($bl19-theme-accent, 50);
$bl-accent-color-middle: mat.get-color-from-palette($bl19-theme-accent, 200);
$bl-accent-color-dark: mat.get-color-from-palette($bl19-theme-accent, 500);

body {
    margin: 0;
    padding: 0;
    background-color: $bl-background-color-middle;
}

html,
body {
    font-family: Quicksand, sans-serif;
}

// this is to to disable text selecting on desired areas
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
}

// hides scrollbar
::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
}

.bl19LightBackground {
    background-color: $bl-background-color-light;
}

.bl19DarkerBackground {
    background-color: $bl-background-color-dark;
}

.bl19inputFild {
    width: 100%;
}

.blcenterelement {
    position: relative;
    display: flex;
    justify-content: center;
}

.bl-border {
    border-left: 2px solid $bl-background-color-middle;
    margin-bottom: 10px;
}



// layout
.layoutRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.layoutRowSpaceBetween {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-between;
}


.layoutColumn {
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    justify-content: center;
}





// ++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++++++++++ How to show Cards ++++++++++++++++++++++++
// +++++++++++ Leitungsteam, Dabei sein +++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++

// use fxflex to order event cards:
@mixin max-width($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}


.container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.blitem {
    margin: 10px;
    flex: 1 1 100%;
    
}

@include max-width(992px) {
    .blitem {
        flex-basis: 25%;
    }
}

@include max-width(640px) {
    .blitem {
        flex-basis: 50%;
    }
}

// Modern Card Styles
.bl19-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 28px rgba(0, 0, 0, 0.15);
  }
  
  &.no-hover {
    &:hover {
      transform: none;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
    }
  }
}

.mat-card {
  @extend .bl19-card;
}
